import React from "react";
function Resume(props) {
  const {year, position, graduation, university, company, details, detailsTwo, detailsThree, detailsFour, detailsFive} = props.resumeData;
  
  return (
    <div className="mi-resume mt-30">
      <div className="mi-resume-summary">
        <h6 className="mi-resume-year">{year}</h6>
      </div>
      <div className="mi-resume-details">
        <h5>{position || graduation}</h5>
        <h6 className="mi-resume-company">{company || university}</h6>
        <ul>
          {!details ? null : <li>
            {details}
          </li>}
          {!detailsTwo ? null : <li>
          {detailsTwo}
          </li>} 
          {!detailsThree ? null : <li>
            {detailsThree}
            </li>} 
          {!detailsFour ? null : <li>
          {detailsFour}
          </li>} 
          {!detailsFive ? null : <li>
            {detailsFive}
            </li>} 
      </ul> 
      </div>
    </div>
  );
};

export default Resume;
