import Mock from "../mock";

const database = {
  information: {
    name: 'Cassandra Lin',
    aboutContent: "I am a frontend web and mobile developer. I create web applications and mobile apps, working closely with the backend to write production level code.",
    //age: 24,
    phone: '',
    nationality: '',
    language: '',
    email: '',
    address: '',
    freelanceStatus: '',
    socialLinks: {
      facebook: '',
      twitter: '',
      pinterest: '',
      behance: '',
      linkedin: 'https://www.linkedin.com/in/cassandra-lin-8949b864/',
      dribbble: '',
      github: 'https://github.com/cassandralin'
    },
    brandImage: '/images/IMG_6875.jpeg',
    aboutImage: '/images/IMG-9309.jpeg',
    aboutImageLg: '/images/IMG-9309.jpeg',
    //cvfile: '/files/empty.pdf'
  },
  services: [
    {
      title: "Web Design",
      icon: 'color-pallet',
      details: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem tenetur ratione quod."
    },
    {
      title: "Web Development",
      icon: 'code',
      details: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem tenetur ratione quod."
    },
    {
      title: "Mobile Application",
      icon: 'mobile',
      details: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem tenetur ratione quod."
    }
  ],
  reviews: [
    {
      id: 1,
      content: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Expedita impedit nobis tempore quaerat quibusdam, aliquid maxime tempora.",
      author: {
        name: 'Burdette Turner',
        designation: 'Web Developer, Abc Company'
      }
    },
    {
      id: 2,
      content: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Expedita impedit nobis tempore quaerat quibusdam.",
      author: {
        name: 'Susan Yost',
        designation: 'Client'
      }
    },
    {
      id: 3,
      content: "Lorem ipsum dolor, sit amet consectetur adipisicing elit.",
      author: {
        name: 'Irving Feeney',
        designation: 'Fiverr Client'
      }
    }
  ],
  skills: [
    {
      title: "ReactJS",
      //value: 80
    },
    {
      title: "React Native",
      //value: 80
    },   
    {
      title: "Javascript",
      //value: 70
    },
    {
      title: "HTML5",
      //value: 95
    },
    {
      title: "CSS",
      //value: 90
    },
    {
      title: "jQuery",
      //value: 85
    },
    {
      title: "Node",
      //value: 65
    }
  ],
  portfolios: [
    {
      id: 1,
      title: "Happy Hour",
      subtitle: "An app that allows you to find happy hours based on location, with toggles to search for food or drink only deals.",
      imageUrl: "/images/Marker3.png",
      largeImageUrl: [
        "/images/Marker3.png",
        "/images/BarCrawlFeature5.png",
        "/images/FoodToggle4.png",
        "/images/CurrentLocation2.png"
      ],
      url: 'https://github.com/cassandralin/Happy-Hour'
    },
    {
      id: 2,
      title: "Scheduler",
      subtitle: "Scheduler is a web application built with React and Storybook that uses Jest and Cypress for testing and allows users to book an appointment to the given week.",
      imageUrl: "/images/1Initial.png",
      largeImageUrl: [
        "/images/1Initial.png",
        "/images/2AddAppointment.png",
        "/images/3DeleteAppointment.png"
      ],
      url: 'https://github.com/cassandralin/schedulerApril'
    },
    {
      id: 3,
      title: "GOAT Notes",
      subtitle: "A to-do list that can be considered the greatest of all time, it autocategorizes your list into 5 different categories.",
      imageUrl: "/images/GOATNotes2.png",
      largeImageUrl: [
        "/images/GOATNotes2.png",
        "/images/GOATNotes1.png",
        "/images/GOATNotes3.png"
      ],
      url: 'https://github.com/cassandralin/Quick-Notes'
    }
  ],
  experience: {
    workingExperience: [
      // {
      //   id: 1,
      //   year: "Sep 2020 - Present",
      //   position: "Front End Developer and Jr. Project Coordinator",
      //   company: "Gaggle Studios, Inc.",
      //   details: "Designed website to match new branding/company needs and increased website traffic by over 400% ",
      //   detailsTwo: "Worked in Angular to integrate features related to customer flow",
      //   detailsThree: "Coordinated with designers, CTO, and other developers to create timelines and procedure templates to ensure deadlines are met",
      //   detailsFour: "Managed marketing channels by researching trends and improving SEO score, increased customer engagement, and grew followers by 65%"
      // },
      // {
      //   id: 2,
      //   year: "Jul 2020 - Sep 2020",
      //   position: "React Native Developer",
      //   company: "The BOGO App",
      //   details: "Using React Native, Expo, Postman, Figma, and Cypress to create an iOS/Android app that allows users to find buy one get one deals for restaurants, retail, etc.",
      //   detailsTwo: "Created new wireframes in Figma and discussed adjustments to current wireframes to match business needs with founder",
      //   detailsThree: "Collaborated with back end developer on creation and structure of required API's"
      // },
      {
        id: 3,
        year: "Apr 2020 - Jul 2020",
        position: "React Developer (Contract)",
        company: "Gigeco Inc.",
        details: "Worked in React, HTML, CSS, Cypress and Postman to create web applications for insurance adjacent companies",
        detailsTwo: "Implemented features related to invoices/payslips, audit reporting",
        detailsThree: "Created base mockups for new company using JavaScript, React and Material UI, replicating designs from designer and discussed the structure of new API’s needed",
        detailsFour: "Integrated and wrote Cypress testing",
        detailsFive: "Managed project in Jira and discussing with teammates reasonable time lines and progress"
      },
      {
        id: 4,
        year: "Jun 2018 - Nov 2019",
        position: "Investment Specialist",
        company: "TD Bank",
        details: "Consistently exceeded $1.5 million quarterly goal of invested assets",
        detailsTwo: "Developed relationships with new and existing clients by providing up to date, well researched financial advice as well as successfully referrals to Wealth Partners to reach goal"
      }
    ],
    educationExperience: [
      {
        id: 1,
        year: "Jan 2020 - Mar 2020",
        graduation: "Full Stack Web Development Diploma",
        university: "Lighthouse Labs",
        //details: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quas, magni mollitia, aspernatur consequatur accusamus vero eum facere exercitationem velit suscipit ipsam placeat libero. Deleniti exercitationem nostrum quasi. Molestiae, vel porro."
      },
      {
        id: 2,
        year: "Sep 2019",
        graduation: "Certified Scrum Product Owner (CSPO)",
        university: "Scrum Alliance",
        //details: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quas, magni mollitia, aspernatur consequatur accusamus vero eum facere exercitationem velit suscipit ipsam placeat libero. Deleniti exercitationem nostrum quasi. Molestiae, vel porro."
      },
      {
        id: 3,
        year: "Sept 2010 - May 2015",
        graduation: "Bachelor of Arts",
        university: "Wilfrid Laurier University",
        //details: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quas, magni mollitia, aspernatur consequatur accusamus vero eum facere exercitationem velit suscipit ipsam placeat libero. Deleniti exercitationem nostrum quasi. Molestiae, vel porro."
      }
    ]
  },
  blogs: [
    {
      id: 1,
      title: 'My Journey to Lighthouse Labs',
      featuredImage: '/images/IMG_6273.jpeg',
      filesource: '../../blog/my-journey-to-lhl-blog.md',
      createDay: "19",
      createMonth: 'August',
      createYear: "2020"
    },
    {
      id: 2,
      title: 'Lessons Learned from Working from Home',
      featuredImage: '/images/IMG_7517.jpeg',
      filesource: '../../blog/lessons-learned-from-wfh-blog.md',
      createDay: "19",
      createMonth: 'August',
      createYear: "2020"
    }
  ],
  contactInfo: {
    //phoneNumbers: ['(437)244-7101'],
    emailAddress: ['cassandralin92@gmail.com'],
    address: "Toronto, Ontario, Canada"
  }
}


Mock.onGet("/api/information").reply(config => {
  const response = database.information;
  return [200, response];
});

Mock.onGet("/api/services").reply(config => {
  const response = database.services;
  return [200, response];
});

Mock.onGet("/api/reviews").reply(config => {
  const response = database.reviews;
  return [200, response];
});

Mock.onGet("/api/skills").reply(config => {
  const response = database.skills;
  return [200, response];
});

Mock.onGet("/api/portfolios").reply(config => {
  const response = database.portfolios;
  return [200, response];
});

Mock.onGet("/api/experience").reply(config => {
  const response = database.experience;
  return [200, response];
});

Mock.onGet("/api/blog").reply(config => {
  const response = database.blogs;
  return [200, response];
});

Mock.onGet("/api/contactinfo").reply(config => {
  const response = database.contactInfo;
  return [200, response];
});